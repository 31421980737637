import React from 'react'
import Link from 'gatsby-link'

import Layout from '../components/Layout'

import psrf_logo_square from '../img/psrf_logo_square.png'
import janelle from '../img/psrf/board/janelle.jpg'
import rosie from '../img/psrf/board/rosie.jpg'
import lexi from '../img/psrf/board/lexi.jpg'
import elana from '../img/psrf/board/elana.jpg'
import ashley from '../img/psrf/board/ashley3.jpg'
import cailin from '../img/psrf/board/cailin.jpg'
import kendall from '../img/psrf/board/kendall.jpg'
import kathleen from '../img/psrf/board/kathleen.jpg'
import victoria from '../img/psrf/board/victoria.jpg'
import belle from '../img/psrf/board/belle.png'
import bridget from '../img/psrf/board/bridget.png'
import caroline from '../img/psrf/board/caroline_king.jpg'
import taylor from '../img/psrf/board/taylor_gilger.jpg'
import tara from '../img/psrf/board/TZahnke.jpg'

export default class VolunteersPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Board of Directors &amp; Volunteers</h1>
              <p>
                The Foundation is governed by seven Board of Directors, who
                serve four year terms. The Board of Directors and volunteers have the
                opportunity to contribute their knowledge and experience in
                various areas that will be useful in strengthening the future of
                the Foundation as we strive to fulfill the mission of the Phi
                Sigma Rho Foundation to the fullest.
              </p>
              <h2>Board of Directors</h2>
              <div className="board-volunteers-grid-container">
                <div className="board-volunteers">
                  <img src={janelle} />
                  <h4>Janelle Becker</h4>
                  <div>President</div>
                  <div>Beta – The Ohio State University</div>
                  <small>
                    <a href="mailto:president@phisigmarhofoundation.org">
                      president@phisigmarhofoundation.org
                    </a>
                  </small>
                </div>
                <div className="board-volunteers">
                  <img src={belle} />
                  <h4>Belle Riehemann</h4>
                  <div>Vice President of Strategic Projects</div>
                  <div>Alpha – Purdue University</div>
                  <small>
                    <a href="mailto:strategy@phisigmarhofoundation.org">
                      strategy@phisigmarhofoundation.org
                    </a>
                  </small>
                </div>
                <div className="board-volunteers">
                  <img src={taylor} />
                  <h4>Taylor Gilger</h4>
                  <div>Vice President of Donor Relations</div>
                  <div>Beta - Ohio State University</div>
                  <small>
                    <a href="mailto:donorengagement@phisigmarhofoundation.org">
                      donorengagement@phisigmarhofoundation.org
                    </a>
                  </small>
                </div>
                <div className="board-volunteers">
                  <img src={tara} />
                  <h4>Tara Zahnke</h4>
                  <div>Vice President of Publicity</div>
                  <div>Beta - Ohio State University</div>
                  <small>
                    <a href="mailto:publicity@phisigmarhofoundation.org">
                      publicity@phisigmarhofoundation.org
                    </a>
                  </small>
                </div>
                <div className="board-volunteers">
                  <img src={lexi} />
                  <h4>Lexi Hieronimus</h4>
                  <div>Vice President of Programming</div>
                  <div>Alpha Zeta – Wright State University</div>
                  <small>
                    <a href="mailto:programming@phisigmarhofoundation.org">
                      programming@phisigmarhofoundation.org
                    </a>
                  </small>
                </div>
                <div className="board-volunteers">
                  <img src={rosie} />
                  <h4>Rosalie Krob</h4>
                  <div>Treasurer</div>
                  <div>Alpha – Purdue University</div>
                  <small>
                    <a href="mailto:treasurer@phisigmarhofoundation.org">
                      treasurer@phisigmarhofoundation.org
                    </a>
                  </small>
                </div>
                <div className="board-volunteers">
                  <img src={caroline} />
                  <h4>Carley King</h4>
                  <div>Secretary</div>
                  <div>Sigma – Oregon State University</div>
                  <small>
                    <a href="mailto:secretary@phisigmarhofoundation.org">
                      secretary@phisigmarhofoundation.org
                    </a>
                  </small>
                </div>
              </div>

              <div className="full-width-image-container red-section section">
                <section
                  className="container"
                  style={{ textAlign: 'center' }}
                >
                  <h2>Volunteer with Us!</h2>
                  <p>
                  The Phi Sigma Rho Foundation could not function without the time its volunteers have put into the organization. If you are interested in making a difference by volunteering with the Foundation, fill out the Volunteer Interest Form on the Phi Rho Portal or contact the Foundation HR Manager for more information.
                  </p>
                  <a
                    href="https://portal.phisigmarho.org"
                    className="button is-large is-rounded"
                    style={{
                      fontFamily: 'Quicksand',
                      fontWeight: 'bold',
                      display: 'block',
                      color: '#8e2344',
                      backgroundColor: 'white',
                      border: '3px #8e2344 solid',
                      borderRadius: 0,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '40%',
                      minWidth: '200px',
                    }}
                  >
                    Phi Rho Portal
                  </a>
                </section>
              </div>

              <div>
                <h2>Keep in Mind!</h2>
                <ul>
                  <li>
                    Officers are elected every odd year before Convention by the
                    incumbent Board of Directors. A nominating committee is used
                    to find and recruit the best volunteers beginning in
                    February, and applications and interviews are required. All
                    applicants are notified in May of the Board's decision.
                  </li>
                  <li>
                    Volunteers are needed throughout the year, and positions
                    under officers and on committees are posted as needed. Some
                    volunteer opportunities are seasonal and may require more
                    work during a specific period of time.
                  </li>
                </ul>
              </div>

              <br />
              <p>
                <Link to="/">← Go Home</Link>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
